import React from "react"

import Layout from "../../../components/Layout";

export default function About() {
    return <Layout>
        <div>hey hey</div>
        <div>hey hey</div>
        <div>hey hey</div>
        <div>hey hey</div>
    </Layout>
}
